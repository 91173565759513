<template>
  <nav aria-label="breadcrumb" class="rk-breadcrumbs">
    <ol
      vocab="https://schema.org/"
      typeof="BreadcrumbList"
      class="rk-list rk-list--breadcrumbs breadcrumb"
    >
      <li class="breadcrumb-item">
        <Btn :link="true" path="/" style-opts="rk-btn--icon me-1">
          <i class="rk-icon rk-icon--text-xs fontello icon-sys-home-solid"></i>
        </Btn>
      </li>
      <li
        v-for="(crumb, index) in crumbs"
        :key="index"
        property="itemListElement"
        typeof="ListItem"
        class="breadcrumb-item active"
        :class="{
          'breadcrumbs-step-back': index !== Object.keys(crumbs).length - 1,
        }"
        aria-current="page"
      >
        <nuxt-link
          property="item"
          typeof="WebPage"
          :to="crumb.path"
          class="rk-link"
        >
          <span property="name">{{
            $route.fullPath === crumb.path && title !== null
              ? title
              : crumb.title
          }}</span>
        </nuxt-link>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { capitalize } from 'lodash'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    const crumbs: any[] = []
    const recentLabel: string | undefined = undefined
    const recentDescription: string | undefined = undefined

    return {
      recentDescription,
      recentLabel,
      crumbs,
    }
  },
  methods: {
    myCrumbs() {
      this.crumbs = []
      const fullPath = this.$route.fullPath
      const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/')
      let pathSet = ''
      params.forEach((param: any, i: number) => {
        pathSet = pathSet + '/' + param
        this.crumbs.push({
          title: capitalize(params[i]),
          path: pathSet,
        })
      })
      return this.crumbs
    },
    onUrlChange() {
      this.myCrumbs()
    },
  },
  watch: {
    $route: [{ immediate: true, deep: true, handler: 'onUrlChange' }],
  },
})
</script>

<style lang="scss" scoped>
@import '/assets/scss/template/components/_breadcrumbs.scss';
</style>
